<template>
  <v-container>
    <div v-if="isError"> {{messages.error_api}} </div>
    <div v-else-if="isLoading">
      <vue-content-loading :width="300" :height="100">
        <rect x="18" y="62" rx="2" ry="2" width="73" height="6" />
        <rect x="19" y="73" rx="2" ry="2" width="73" height="6" />
        <rect x="13" y="10" rx="2" ry="2" width="83" height="44" />
        <rect x="58" y="63" rx="0" ry="0" width="1" height="1" />
        <rect x="108" y="62" rx="2" ry="2" width="73" height="6" />
        <rect x="108" y="73" rx="2" ry="2" width="73" height="6" />
        <rect x="103" y="10" rx="2" ry="2" width="83" height="44" />
        <rect x="148" y="63" rx="0" ry="0" width="1" height="1" />
      </vue-content-loading>
    </div>
    <v-container>
      <v-expansion-panels focusable :value="0">
        <v-expansion-panel v-for="(date, index) in dates" :key="index"  style="border: solid #9ba88c 6px">
           <v-expansion-panel-header
            style="font-size: 1.375rem"
            :expand-icon="getIcon(index)"
            color="greenlight--text"
            @click="expandToggled(index)"
            >
            <span
              v-html="
                replaceVars($store.state.text.day_tab_title.value, {
                  dayIndex: dates.indexOf(date) + 1,
                  day: new Date(date).toLocaleDateString($store.state.lang),
                })
              "
            ></span>
            <template v-slot:actions>
            <v-icon color="pink">
              {{getIcon(index)}}
            </v-icon>
          </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="start" class="justify-space-around" cols="6">
              <v-col
                v-for="(room, indexRoom) in rooms"
                :key="indexRoom"
                cols="12"
                md="5"
              >
                <v-card class="my-4">
                  <v-img  id="custom-img" v-if="room.image" height="400" width="650" :src="room.image.url"></v-img>
                  <v-card-title>{{ room.name | capitalize }}</v-card-title>
                  <v-card-subtitle v-if="room.desc">
                    <span v-html="$store.state.text.label_sleeping_room_features.value"></span>
                    <span v-html="room.desc"></span>
                  </v-card-subtitle>
                  <v-card-text>
                    <v-checkbox
                      color="#E99688"
                      :label="$store.state.text.label_sleeping_room_select.value"
                      v-model="selected[getIndex(date, room)]"
                      @change="prefill(getIndex(date, room));$forceUpdate();"
                    ></v-checkbox>
                    <!--<div v-if="isSelected(date, room)">-->
                    <div v-if="selected[getIndex(date, room)]">
                      <v-text-field
                        name="nb_single"
                        :label="$store.state.text.label_sleeping_room_single_qty.value"
                        v-model="quantity_single[getIndex(date, room)]"
                        outlined
                        :rules="numberSingleRules"
                        type="number"
                        @change="buildReservations();$forceUpdate();"
                      ></v-text-field>
                      <v-text-field
                        name="nb_twin"
                        :label="$store.state.text.label_sleeping_room_twin_qty.value"
                        v-model="quantity_twin[getIndex(date, room)]"
                        :rules="numberTwinRules"
                        outlined
                        type="number"
                        @change="buildReservations()"
                      ></v-text-field>
                    </div>
                    <v-card-subtitle v-if="room.infos">
                      <span v-html="$store.state.text.label_more_infos_sleeping_rooms.value"></span>
                      <span v-html="room.infos"></span>
                    </v-card-subtitle>
                    <v-card-subtitle v-if="room.infos">
                      <span v-html="replaceVars($store.state.text.sleeping_room_price.value, {'ATIPrice' : room.price, 'PretaxPrice' : room.price_pretax})"></span>
                    </v-card-subtitle>
                    <v-card-subtitle>
                      <span v-html="replaceVars($store.state.text.twin_extra_charge.value, {'ATIPrice' : $store.state.otherParams.twin_extra_charge, 'PretaxPrice' : $store.state.otherParams.twin_extra_charge_pretax})"></span>
                    </v-card-subtitle>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="start" class="justify-space-around" cols="6">
              <v-btn
                   id="custom-btn"
                   class="white--text"
                   color="#9BA88C"
              @click="copyConfigToNextDays(date)"
              v-if="dates.indexOf(date)!=(dates.length-1)">
                <v-icon>mdi-content-copy</v-icon>
                <span id="text-copy-next-day">{{$store.state.text.copy_next_days_btn_text.value}}</span>
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <v-container fluid grid-list-md> </v-container>
  </v-container>
</template>

<style>
@media screen and (max-width: 800px) {
  .v-label {
    font-size: 12px;
  }
  .v-card__title {
    font-size: 18px;
  }
  .v-card__text {
    padding: 0;
  }
}
</style>

<script>
import VueContentLoading from "vue-content-loading";

export default {
  name: "stepRooms",
  components: {
    VueContentLoading,
  },
  data() {
    return {
      messages: {
        error_api: `Une erreur est survenue lors du chargement du formulaire de réservation des chambres`,
      },
      numberSingleRules: [
        //(v) => !!v || this.$store.state.text.valid_nb_single_rooms_required.value,
        (v) =>
          (v >= 0) || this.$store.state.text.valid_nb_single_rooms_non_negative.value,
      ],
      numberTwinRules: [
        (v) =>
          (v >= 0) || this.$store.state.text.valid_nb_twin_rooms_non_negative.value,
      ],
      isError: false,
      isLoading: false,
      selected: [],
      quantity_single: [],
      quantity_twin: [],
      expandOpen: [],
    };
  },
  computed: {
    rooms() {
      return this.$store.state.stepRooms.rooms;
    },
    dates() {
      var tmpdates = [];
      var startDateString = this.$store.state.stepEvent.date_start;
      var startDate = new Date(startDateString);
      var endDateString = this.$store.state.stepEvent.date_end;
      var endDate = new Date(endDateString);

      var datesBetween = this.allDatesBetween(startDate, endDate);
      datesBetween.forEach((date) => {
        tmpdates.push(date);
      });
      return tmpdates;
    },
    stepRooms: {
      get() {
        return this.$store.state.stepRooms;
      },
      /* set(value) {
          this.$store.commit('ADD_ROOMS', value)
        } */
    },
  },
  methods: {
    replaceVars(
      source,
      replacements,
      openingDelimiter = "[",
      closingDelimiter = "]"
    ) {
      var res = source;
      for (const Old in replacements) {
        var New = replacements[Old];
        var regex = openingDelimiter + Old + closingDelimiter;
        res = res.replace(regex, New.toString());
      }
      return res;
    },
    getIcon(index)
    {
      return this.expandOpen[index] ? '$minus' : '$plus';
    },
    expandToggled(index)
    {
      let previousState = this.expandOpen[index];
      for(let i = 0; i<this.expandOpen.length; i++)
      {
        this.expandOpen[i] = false;
      }
      this.expandOpen[index] = !previousState;
      this.$forceUpdate();
    },
    prefill(dateRoomIndex)
    {
        this.quantity_single[dateRoomIndex] = this.$store.state.stepEvent.event_nb_people;
        this.quantity_twin[dateRoomIndex] = 0;
    },
    getIndex(date, room) {
      var index = date + "-" + room.ID.toString();
      return index;
    },
    dateDiff(date2, date1) {
      var diff = {}; // Initialisation du retour
      var tmp = date2 - date1;
      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes
      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes
      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures
      tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
      diff.day = tmp;
      return diff;
    },
    allDatesBetween(startDate, endDate) {
      var res = [];
      for (var i = 0; i <= this.dateDiff(endDate, startDate).day; i++) {
        var newDate = new Date(startDate);
        newDate.setDate(startDate.getDate() + i);
        res.push(newDate);
      }
      return res;
    },
    copyConfigToNextDays(dateStartingPoint) {
      var datesToCopy = this.dates.slice(
        this.dates.indexOf(dateStartingPoint) + 1,
        this.dates.length
      );
      datesToCopy.forEach((dest) => {
        this.rooms.forEach((room) => {
          if (this.selected[this.getIndex(dateStartingPoint, room)]) {
            this.selected[this.getIndex(dest, room)] = this.selected[
              this.getIndex(dateStartingPoint, room)
            ];
            this.quantity_single[this.getIndex(dest, room)] = this.quantity_single[
              this.getIndex(dateStartingPoint, room)
            ];
            this.quantity_twin[this.getIndex(dest, room)] = this.quantity_twin[
              this.getIndex(dateStartingPoint, room)
            ];
          } else {
            this.selected[this.getIndex(dest, room)] = false;
          }
        });
      });
      this.$forceUpdate();
      this.buildReservations();
    },
    buildReservations() {
      var tmpReservations = [];
      this.dates.forEach((date) => {
        var oneReservationAtLeast = false;
        this.rooms.forEach((room) => {
          if (this.selected[this.getIndex(date, room)] && (this.quantity_single[this.getIndex(date, room)] + this.quantity_twin[this.getIndex(date, room)]) > 0)
            oneReservationAtLeast = true;
        });
        if (oneReservationAtLeast) {
          tmpReservations.push({
            journee: date,
            rooms: [],
          });
          this.rooms.forEach((room) => {
            if (this.selected[this.getIndex(date, room)]) {
              tmpReservations[tmpReservations.length - 1].rooms.push({
                s_room: [{
                  ID: room.ID}
                  ],
                quantity_single: this.quantity_single[this.getIndex(date, room)],
                quantity_twin: this.quantity_twin[this.getIndex(date, room)],
              });
            }
          });
        }
      });
      this.$store.commit("ADD_ROOMS", tmpReservations);
    },
    isSelected(date, room) {
      console.log(date, room);
      //return true;
      if (typeof room !== 'undefined') {
        return this.selected[this.getIndex(date, room)];
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.dates.forEach((date) => {
      this.expandOpen[this.dates.indexOf(date)] = false;
    });
    this.expandOpen[0] = true;
  },
  updated() {
    this.buildReservations();
  },
  created() {
    this.$store.dispatch("getRooms");
  },
};
</script>