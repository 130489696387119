<template>
  <v-container>
    <v-row no-gutters cols="12" id="custom-row-600">
      <v-col cols="12" sm="6" id="custom-col-600">
        <span v-html="$store.state.text.mandatory_fields_warning.value"> </span>
        <v-row cols="12">
          <v-col cols="6">
            <v-text-field
              v-model="stepEvent.event_name"
              :label="messages.event_name"
              outlined
              :rules="eventTypeRules"
            >
            <template slot="label">
            <span v-html="$store.state.text.label_event_name.value"> </span>
          </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <span v-html="$store.state.text.event_dates_warning_title.value"> </span>
        </v-row>
        <v-row style="font-size: 8pt">
          <span v-html="$store.state.text.event_dates_warning.value"> </span>
        </v-row>
        <v-row cols="12">
          <v-col cols="6">
            <v-menu
              ref="menu_start"
              v-model="stepEvent.menu_start"
              :close-on-content-click="false"
              :return-value.sync="date_start"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_start"
                  label="Date de début *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                :rules="dateStartRules"
                >
                <template slot="label">
            <span v-html="$store.state.text.label_event_date_start.value"> </span>
          </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="date_start"
                :allowed-dates="allowedDate"
                no-title
                scrollable
                @change="$refs.menu_start.save(date_start); changeDates()"
                :locale="$store.state.lang"
                first-day-of-week="1"
                color="#E99688"
              > <!-- TODO: set validation rule -->
                <v-spacer></v-spacer>
                <v-btn text color="black" @click="stepEvent.menu_start = false">
                  {{$store.state.text.cancel_btn_text.value}}
                </v-btn>
                <v-btn text color="#E99688" @click="$refs.menu_start.save(date_start); changeDates()"> {{$store.state.text.ok_btn_text.value}} </v-btn>
              </v-date-picker>
            </v-menu>
            </v-col>
          <v-col cols="6">
            <v-menu
              ref="menu_end"
              v-model="stepEvent.menu_end"
              :close-on-content-click="false"
              :return-value.sync="stepEvent.date_end"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_end"
                  label="Date de fin *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                :rules="dateEndRules"
                >
                <template slot="label">
            <span v-html="$store.state.text.label_event_date_end.value"> </span>
          </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="date_end"
                :allowed-dates="allowedDate"
                no-title
                @change="$refs.menu_end.save(date_end); changeDates()"
                scrollable
                :locale="$store.state.lang"
                first-day-of-week="1"
                color="#E99688"
              >
                <v-spacer></v-spacer>
                <v-btn text color="black" @click="stepEvent.menu_end = false">
                  {{$store.state.text.cancel_btn_text.value}}
                </v-btn>
                <v-btn text color="#E99688" @click="$refs.menu_end.save(date_end); changeDates()"> {{$store.state.text.ok_btn_text.value}}</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row cols="12" id="custom-row-600">
          <v-col cols="6">
            <v-text-field
              v-model="nbPeople"
              @change="emptyMeals()"
              @keyup="emptyMeals()"
              :label="messages.event_nb_people"
              type="number"
              outlined
              :rules="eventNbPeopleRules"
            >
            <template slot="label">
            <span v-html="$store.state.text.label_event_nb_people.value"> </span>
          </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row style="padding-bottom: 20px">
          <span v-html="$store.state.text.event_needs_warning.value"> </span>
        </v-row>
        <v-checkbox
          v-for="(need, indexNeed) in $store.state.text.specific_needs.value"
          v-model="$store.state.stepEvent.specific_needs[indexNeed]"
          :key="indexNeed"
          class="my-n4"
          color="#E99688"
          :label="need.long"
          @change="charlotte()"
        >
        </v-checkbox>
        <v-textarea
          class="mt-5"
          color="#E99688"
          v-model="stepEvent.customers_comment"
          :label="$store.state.text.label_event_comment.value"
        >
          <template slot="label">
            <span v-html="$store.state.text.label_event_comment.value"></span>
          </template>
        </v-textarea>
      </v-col>
      <v-col class="d-none d-sm-inline" cols="12" sm="6" id="custom-img-600">
        <v-img
          id="custom-img-600"
          class="ma-10"
          :src="$store.state.otherParams.image_step_2_url"
          transition="scale-transition"
          aspect-ratio="1.0"
          contain
        ></v-img>
      </v-col>
    </v-row>
    <v-container fluid grid-list-md> </v-container>
    <div class="text-center">
      <v-dialog v-model="changeDateWarningDialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$store.state.text.dates_change_dialog_title.value}}
          </v-card-title>

          <v-card-text style="padding-top: 20px">
            <span v-html="$store.state.text.dates_change_dialog.value"></span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              text
              @click="changeDateWarningDialog = false"
            >
              {{$store.state.text.cancel_btn_text.value}}
            </v-btn>
            <v-btn
              class="white--text"
              color="#9BA88C"
              @click="
                changeDateWarningDialog = false;
                allowChangingDates = true;
                refreshDates();
              "
            >
              {{$store.state.text.yes_btn_text.value}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dateOutOfRangeDialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$store.state.text.dates_out_limits_dialog_title.value}}
          </v-card-title>

          <v-card-text style="padding-top: 20px;">
            <span v-html="replaceVars($store.state.text.dates_out_limits_dialog.value,{'lowerLimit' : $store.state.otherParams.duration_limits.min,'upperLimit' : $store.state.otherParams.duration_limits.max})"></span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="white--text"
                color="#9BA88C" @click="dateOutOfRangeDialog = false">
              {{ $store.state.text.ok_btn_text.value }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="datesInverted" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{ $store.state.text.dates_reverse_dialog_title.value }}
          </v-card-title>

          <v-card-text style="padding-top: 20px;">
            <span v-html="$store.state.text.dates_reverse_dialog.value"></span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="white--text" color="#9BA88C" @click="datesInverted = false">
              {{ $store.state.text.ok_btn_text.value }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<style>
@media screen and (max-width: 600px) {
  #custom-img-600 {
    display: none;
  }
  #custom-row-600 {
    display: flex;
    justify-content: center;
    align-content: stretch;
  }
  #custom-col-600 {
    max-width: 100%;
  }
}
</style>

<script>
export default {
  name: "stepEvent",
  data() {
    return {
      date_start: "",
      date_end: "",
      messages: {
        event_name: `Nom de l'événement *`,
        date_start: "Date de début",
        date_end: "Date de fin",
        event_nb_people: `Nombre de participants *`,
        need_spa: `J'ai besoin d'un spa`,
        need_care: `J'ai besoin de soin`,
        need_giftcard: `J'ai besoin de bon cadeau`,
        need_coach: `J'ai besoin de coach sportif`,
        need_fun_animation: `J'ai besoin d'animation ludique`,
        need_cultural_animation: `J'ai besoin d'animation culturelle (musée, etc.)`,
        need_bistronomic_animation: `J'ai besoin d'animation bistronomique (dégustation de vins, cours de cuisine)`,
        need_tablets: `J'ai besoin de tablettes amovibles en salles de réunion`,
        customers_comment: `Ajouter un commentaire`,
      },
      eventTypeRules: [(v) => !!v || this.$store.state.text.valid_required_event_name.value],
      eventNbPeopleRules: [
        (v) => !!v || this.$store.state.text.valid_required_event_nb_people.value,
        (v) =>
          (v && v >= 1) || this.$store.state.text.valid_required_event_nb_people_positive.value,
      ],
      dateStartRules: [(v) => !!v || this.$store.state.text.valid_required_event_date_start.value],
      dateEndRules: [(v) => !!v || this.$store.state.text.valid_required_event_date_end.value],
      changeDateWarningDialog: false,
      dateOutOfRangeDialog: false,
      datesInverted: false,
      firstSet: true,
      allowChangingDates: true,
      nbPeople: 0,
    };
  },
  computed: {
    stepEvent: {
      get() {
        return this.$store.state.stepEvent;
      },
      set(value) {
        this.$store.commit("ADD_EVENT", value);
      },
    },
  },
  watch: {
    date_start: "refreshDates",
  },
  methods: {
    charlotte()
    {
      console.log(this.$store.state.stepEvent);
    },
    replaceVars(source, replacements, openingDelimiter = "[", closingDelimiter = "]") {
            var res = source;
            for (const Old in replacements) {
                var New = replacements[Old];
                var regex = openingDelimiter + Old + closingDelimiter;
                res = res.replace(regex, New.toString());
            }
            return res;
        },
    allowedDate(val) {
      return Date.parse(val) > Date.now();
    },
    emptyMeals() {
      let oldState =
        this.$store.state.stepEvent.nb_people > parseInt(this.$store.state.otherParams.nb_people_max_carte) ? "formula" : "carte";
      let newState = this.nbPeople > parseInt(this.$store.state.otherParams.nb_people_max_carte) ? "formula" : "carte";
      if (oldState != newState) {
        this.$store.commit("DELETE_MEALS");
      }
      this.npPeople = this.nbPeople.replace(/^0+/, '');
      this.$store.state.stepEvent.event_nb_people = this.nbPeople.replace(/^0+/, '');
      this.$forceUpdate();
    },
    changeDates() {
      this.$store.state.datesOK = false;
      if(this.date_start&&this.date_end) {
        let duration =
          this.dateDiff(
            new Date(this.date_end),
            new Date(this.date_start)
          ).day + 1;
        if(duration <= 0)
        {
          this.datesInverted = true
        }
        else if (
          duration > this.$store.state.otherParams.duration_limits.max ||
          duration < this.$store.state.otherParams.duration_limits.min
        ) {
          this.dateOutOfRangeDialog = true;
          
        } else {
          if (this.firstSet) {
            this.allowChangingDates = false;
          } else {
            this.changeDateWarningDialog = true;
          }
          this.firstSet = false;
          this.$refs.menu_start.save(this.date_start);
          this.$refs.menu_end.save(this.date_end);
          this.$store.state.datesOK = true;
        }
      }
    },
    dateDiff(date2, date1) {
      var diff = {}; // Initialisation du retour
      var tmp = date2 - date1;
      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes
      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes
      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures
      tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
      diff.day = tmp;
      return diff;
    },
    refreshDates() {
      if (this.firstSet || this.allowChangingDates) {
          this.$store.state.stepEvent.date_start = this.date_start;
          this.$store.state.stepEvent.date_end = this.date_end;
        this.$forceUpdate();
        this.allowChangingDates = false;
      }
    },
  },
};
</script>