<template>
  <v-container>
    <div v-if="isError">{{ messages.error_api }}</div>
    <div v-else-if="isLoading">
      <vue-content-loading :width="300" :height="100">
        <rect x="18" y="62" rx="2" ry="2" width="73" height="6" />
        <rect x="19" y="73" rx="2" ry="2" width="73" height="6" />
        <rect x="13" y="10" rx="2" ry="2" width="83" height="44" />
        <rect x="58" y="63" rx="0" ry="0" width="1" height="1" />
        <rect x="108" y="62" rx="2" ry="2" width="73" height="6" />
        <rect x="108" y="73" rx="2" ry="2" width="73" height="6" />
        <rect x="103" y="10" rx="2" ry="2" width="83" height="44" />
        <rect x="148" y="63" rx="0" ry="0" width="1" height="1" />
      </vue-content-loading>
    </div>
    <v-container>
      <v-expansion-panels focusable :value="0">
        <v-expansion-panel
          v-for="(date, index) in dates"
          :key="index"
          style="border: solid #9ba88c 6px"
        >
          <v-expansion-panel-header
            style="font-size: 1.375rem"
            :expand-icon="getIcon(index)"
            color="greenlight--text"
            @click="expandToggled(index)"
            ><span
              v-html="
                replaceVars($store.state.text.day_tab_title.value, {
                  dayIndex: dates.indexOf(date) + 1,
                  day: new Date(date).toLocaleDateString($store.state.lang),
                })
              "
            ></span>
            <template v-slot:actions>
              <v-icon color="pink">
                {{ getIcon(index) }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="start" class="justify-space-around" cols="6">
              <v-col
                v-if="
                  nbPeople >
                  parseInt($store.state.otherParams.nb_people_max_carte)
                "
              >
                <v-card class="my-4">
                  <v-card-title>{{$store.state.text.meals_formula_title.value}}</v-card-title>
                  <v-card-subtitle>
                    {{$store.state.text.meals_formula_subtitle.value}}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-radio-group
                      v-model="selected[getIndex(date, 'formula')]"
                    >
                      <div
                        v-for="(formula, indexFormula) in meals.formulas"
                        :key="indexFormula"
                      >
                        <v-radio
                          :label="formula.name"
                          :value="1"
                          v-model="formula.ID"
                          @change="
                            prefill(getIndex(date, 'formula', formula.ID))
                          "
                        >
                        </v-radio>
                        <v-card-subtitle v-if="formula.desc">
                          <span v-html="$store.state.text.benefits_desc_title.value"></span>
                          <span v-html="formula.desc"></span>
                        </v-card-subtitle>
                      </div>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="5"
                v-if="
                  nbPeople <=
                  parseInt($store.state.otherParams.nb_people_max_carte)
                "
              >
                <v-card class="my-4">
                   <v-card-title>{{$store.state.text.meals_carte_title.value}}</v-card-title>
                  <v-card-subtitle>
                    {{$store.state.text.meals_carte_subtitle.value}}
                  </v-card-subtitle>
                  <v-card-text>
                    <div
                      v-for="(category, indexCategory) in meals.categories"
                      :key="indexCategory"
                    >
                      <span v-if="getMealsByCategory(category.term_id).length"
                        ><b>{{ category.name }}</b></span
                      >
                      <v-row>
                        <v-col>
                          <v-select
                            v-if="getMealsByCategory(category.term_id).length"
                            :label="$store.state.text.default_meal_select_option.value"
                            :items="getMealsByCategory(category.term_id)"
                            item-text="display_text"
                            item-value="ID"
                            :value="getSelectedCarte(date, category.term_id)"
                            :no-data-text="$store.state.text.empty_meal_category.value"
                            :clearable="true"
                            @change="
                              setSelectedCarte($event, date, category.term_id)
                            "
                          >
                          </v-select>
                          <div
                            class="mb-4"
                            v-if="
                              getMealsByCategory(category.term_id).length &&
                              description[
                                getIndex(date, 'carte', category.term_id)
                              ]
                            "
                          >
                            <i>
                              <span
                                v-html="
                                  description[
                                    getIndex(date, 'carte', category.term_id)
                                  ]
                                "
                              ></span>
                            </i>
                          </div>
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-if="displayMealQtyField(date, category.term_id)"
                            name="nb_single"
                            label="Nombre de personnes"
                            outlined
                            type="number"
                            :value="getQuantityCarte(date, category.term_id)"
                            :rules="carteNbPeopleRules"
                            @change="
                              setQuantityCarte($event, date, category.term_id)
                            "
                          >
                          <template slot="label">
                              <span v-html="$store.state.text.label_event_nb_people.value"></span>
                          </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row> </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" id="custom-img">
                <v-card
                  class="my-4"
                  v-if="
                    nbPeople <=
                    parseInt($store.state.otherParams.nb_people_max_carte) && meals.images_carte != null && meals.images_carte.length
                  "
                >
                  <v-carousel>
                    <v-carousel-item
                      v-for="item in meals.images_carte"
                      :key="item"
                      :src="item"
                    >
                    </v-carousel-item>
                  </v-carousel>
                </v-card>
                <v-card
                  class="my-4"
                  v-if="
                    nbPeople >
                    parseInt($store.state.otherParams.nb_people_max_carte) && meals.images_formulas!= null && meals.images_formulas.length
                  "
                >
                  <v-carousel id="custom-img" v-if="meals.images_formulas.length">
                    <v-carousel-item
                      v-for="item in meals.images_formulas"
                      :key="item"
                      :src="item"
                    >
                    </v-carousel-item>
                  </v-carousel>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="start" class="justify-space-around" cols="6">
              <v-btn
                class="white--text"
                color="#9BA88C"
                @click="copyConfigToNextDays(date)"
                v-if="dates.indexOf(date) != dates.length - 1"
              >
                <v-icon>mdi-content-copy</v-icon>
                <span id="text-copy-next-day">{{$store.state.text.copy_next_days_btn_text.value}}</span>
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-container>
</template>

<style>
@media screen and (max-width: 800px) {
  .v-label {
    font-size: 12px;
    padding-left: 0;
  }
  .v-card__title {
    font-size: 14px;
    padding-left: 0;
  }
  .v-card__text, .v-card__subtitle {
    padding: 0;
  }
  .v-application ul, ol, p {
  padding-left: 0;
  }
}
</style>

<script>
import VueContentLoading from 'vue-content-loading';

export default {
  name: "stepFood",
  components: {
    VueContentLoading,
  },
  data() {
    return {
      messages: {
        error_api: `Une erreur est survenue lors du chargement du formulaire de réservation des repas`,
      },
      formulaNbPeopleRules: [
        (v) =>
          (v &&
            v > parseInt(this.$store.state.otherParams.nb_people_max_carte)) ||
          this.replaceVars(this.$store.state.text.validation_nb_people_min_formula.value, {'lowerLimit' : this.$store.state.otherParams.nb_people_max_carte})
      ],
      isError: false,
      isLoading: false,
      selected: [],
      quantity: [],
      description: [],
      expandOpen: [],
      carteNbPeopleRules: [
        (v) => !!v || this.$store.state.text.valid_required_event_nb_people.value,
        (v) =>
          (v && v > 0) || this.$store.state.text.valid_required_event_nb_people_positive.value,
      ],
    };
  },
  computed: {
    meals() {
      return this.$store.state.stepFood.meals;
    },
    nbPeople() {
      return this.$store.state.stepEvent.event_nb_people;
    },
    mealsMerged() {
      return this.meals.carte.concat(this.$store.state.stepFood.meals.formulas);
    },
    dates() {
      var tmpdates = [];
      var startDateString = this.$store.state.stepEvent.date_start;
      var startDate = new Date(startDateString);
      var endDateString = this.$store.state.stepEvent.date_end;
      var endDate = new Date(endDateString);

      var datesBetween = this.allDatesBetween(startDate, endDate);
      datesBetween.forEach((date) => {
        tmpdates.push(date);
      });
      return tmpdates;
    },
    stepMeals: {
      get() {
        return this.$store.state.stepMeals;
      },
    },
  },
  methods: {
    replaceVars(
      source,
      replacements,
      openingDelimiter = "[",
      closingDelimiter = "]"
    ) {
      var res = source;
      for (const Old in replacements) {
        var New = replacements[Old];
        var regex = openingDelimiter + Old + closingDelimiter;
        res = res.replace(regex, New.toString());
      }
      return res;
    },
    beautifyPrice(price)
    {
      return parseFloat(price).toFixed(2);
    },
    appendPrice(name, price, price_pretax){
        return name+" - "+this.beautifyPrice(price)+this.$store.state.text.suffix_price.value+" (="+this.beautifyPrice(price_pretax)+this.$store.state.text.suffix_price_pretax.value+")";
    },
    displayMealQtyField(date, category_id) {
      let ret = false;
      ret = this.getMealsByCategory(category_id);
      return ret && this.getSelectedCarte(date, category_id) > 0;
    },
    getIcon(index) {
      return this.expandOpen[index] ? "$minus" : "$plus";
    },
    expandToggled(index) {
      let previousState = this.expandOpen[index];
      for (let i = 0; i < this.expandOpen.length; i++) {
        this.expandOpen[i] = false;
      }
      this.expandOpen[index] = !previousState;
      this.$forceUpdate();
    },
    prefill(dateMealIndex) {
      this.quantity[
        dateMealIndex
      ] = this.$store.state.stepEvent.event_nb_people;
    },
    setSelectedCarte(ID, date, category_id = 0) {
      if (ID != null && ID != "undefined") {
        this.selected[this.getIndex(date, "carte", ID)] = true;
        for (
          var i = 0;
          i < this.meals.carte.length;
          i++ //set meal description
        ) {
          if (this.meals.carte[i].ID == ID) {
            this.description[
              this.getIndex(date, "carte", category_id)
            ] = this.meals.carte[i].description;
          }
        }
        this.prefill(this.getIndex(date, "carte", ID));
      } else if (category_id) {
        //dropdown has been cleared, we remove previously added elements from selected and quantity arrays
        delete this.description[this.getIndex(date, "carte", category_id)];
        this.getMealsByCategory(category_id).forEach((meal) => {
          delete this.selected[this.getIndex(date, "carte", meal.ID)];
          delete this.quantity[this.getIndex(date, "carte", meal.ID)];
        });
      }
      this.$forceUpdate();
    },
    getSelectedCarte(date, category_id = 0) {
      let ret = false;
      this.getMealsByCategory(category_id).forEach((meal) => {
        if (this.selected[this.getIndex(date, "carte", meal.ID)]) {
          ret = meal.ID;
        }
      });
      return ret;
    },
    setQuantityCarte(newQuantity, date, category_id = 0) {
      if (category_id) {
        this.getMealsByCategory(category_id).forEach((meal) => {
          if (this.selected[this.getIndex(date, "carte", meal.ID)])
            this.quantity[this.getIndex(date, "carte", meal.ID)] = newQuantity;
        });
      }
      this.$forceUpdate();
      this.buildReservations();
    },
    getQuantityCarte(date, category_id = 0) {
      let ret = false;
      this.getMealsByCategory(category_id).forEach((meal) => {
        if (this.selected[this.getIndex(date, "carte", meal.ID)]) {
          ret = this.quantity[this.getIndex(date, "carte", meal.ID)];
        }
      });
      return ret;
    },
    getIndex(date, meal_type, ID = "") {
      var index = date + "-" + meal_type + "-" + ID.toString();
      return index;
    },
    dateDiff(date2, date1) {
      var diff = {}; // Initialisation du retour
      var tmp = date2 - date1;
      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes
      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes
      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures
      tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
      diff.day = tmp;
      return diff;
    },
    allDatesBetween(startDate, endDate) {
      var res = [];
      for (var i = 0; i <= this.dateDiff(endDate, startDate).day; i++) {
        var newDate = new Date(startDate);
        newDate.setDate(startDate.getDate() + i);
        res.push(newDate);
      }
      return res;
    },
    getMealsByCategory(term_id_p) {
      let mealsOfCategory = [];
      this.meals.carte.forEach((meal) => {
        meal.type_de_repas.forEach((taxo) => {
          if (taxo.term_id == term_id_p)
          {
           meal.display_text = this.appendPrice(meal.name, meal.prix, meal.prix_pretax);
           mealsOfCategory.push(meal);
          }
        });
      });
      return mealsOfCategory;
    },
    copyConfigToNextDays(dateStartingPoint) {
      var datesToCopy = this.dates.slice(
        this.dates.indexOf(dateStartingPoint) + 1,
        this.dates.length
      );
      datesToCopy.forEach((dest) => {
          if (
            this.selected[
              this.getIndex(dateStartingPoint, "formula")
            ]
          ) {
            this.selected[
              this.getIndex(dest, "formula")
            ] = this.selected[
              this.getIndex(dateStartingPoint, "formula")
            ];
          }
        this.meals.carte.forEach((carte) => {
          if (
            this.selected[this.getIndex(dateStartingPoint, "carte", carte.ID)]
          ) {
            this.selected[
              this.getIndex(dest, "carte", carte.ID)
            ] = this.selected[
              this.getIndex(dateStartingPoint, "carte", carte.ID)
            ];
            this.quantity[
              this.getIndex(dest, "carte", carte.ID)
            ] = this.quantity[
              this.getIndex(dateStartingPoint, "carte", carte.ID)
            ];
          }
        });
      });
      this.sanitizeFormulasSelections();
      this.$forceUpdate();
      this.buildReservations();
    },
    sanitizeFormulasSelections() {
      this.dates.forEach((date) => {
        this.meals.formulas.forEach((formula) => {
          if (this.selected[this.getIndex(date, "formula")] == formula.ID) {
            this.meals.formulas.forEach((formulaToDel) => {
              //we empty all other formula selections
              delete this.selected[
                this.getIndex(date, "formula", formulaToDel.ID)
              ];
            });
            this.selected[this.getIndex(date, "formula", formula.ID)] = true;
          }
        });
      });
    },
    buildReservations() {
      var tmpReservations = [];
      if (
        this.$store.state.stepEvent.event_nb_people >
        parseInt(this.$store.state.otherParams.nb_people_max_carte)
      ) {
        this.sanitizeFormulasSelections();
        this.dates.forEach((date) => {
          var oneReservationAtLeast = false;
          this.mealsMerged.forEach((meal) => {
            if (this.selected[this.getIndex(date, "formula", meal.ID)])
              oneReservationAtLeast = true;
          });
          if (oneReservationAtLeast) {
            tmpReservations.push({
              journee: date,
              meals_formula: [],
            });
            this.meals.formulas.forEach((formula) => {
              if (this.selected[this.getIndex(date, "formula", formula.ID)])
                tmpReservations[tmpReservations.length - 1].meals_formula.push({
                  formula: [{ ID: formula.ID }],
                  quantity: this.$store.state.stepEvent.event_nb_people,
                });
            });
          }
        });
      } else {
        this.dates.forEach((date) => {
          var oneReservationAtLeast = false;
          this.mealsMerged.forEach((meal) => {
            if (this.selected[this.getIndex(date, "carte", meal.ID)])
              oneReservationAtLeast = true;
          });
          if (oneReservationAtLeast) {
            tmpReservations.push({
              journee: date,
              meals_carte: [],
            });
            this.meals.carte.forEach((carte) => {
              if (
                this.selected[this.getIndex(date, "carte", carte.ID)] &&
                this.quantity[this.getIndex(date, "carte", carte.ID)]
              )
                tmpReservations[tmpReservations.length - 1].meals_carte.push({
                  meal: [{ ID: carte.ID }],
                  quantity: this.quantity[
                    this.getIndex(date, "carte", carte.ID)
                  ],
                });
            });
            var disse = this;
            tmpReservations[tmpReservations.length - 1].meals_carte.sort(
              function (a, b) {
                let atermID = 0;
                for (
                  let j = 0;
                  j < disse.$store.state.stepFood.meals.carte.length;
                  j++
                ) {
                  if (
                    disse.$store.state.stepFood.meals.carte[j].ID == a.meal[0].ID
                  ) {
                    atermID =
                      disse.$store.state.stepFood.meals.carte[j].type_de_repas[0]
                        .term_id;
                    break;
                  }
                }

                let aPosition = 0;
                for (
                  let i = 0;
                  i < disse.$store.state.stepFood.meals.categories.length;
                  i++
                ) {
                  if (
                    disse.$store.state.stepFood.meals.categories[i].term_id ==
                    atermID
                  ) {
                    aPosition = parseInt(
                      disse.$store.state.stepFood.meals.categories[i].position
                    );
                  }
                }

                let btermID = 0;
                for (
                  let j = 0;
                  j < disse.$store.state.stepFood.meals.carte.length;
                  j++
                ) {
                  if (
                    disse.$store.state.stepFood.meals.carte[j].ID == b.meal[0].ID
                  ) {
                    btermID =
                      disse.$store.state.stepFood.meals.carte[j].type_de_repas[0]
                        .term_id;
                    break;
                  }
                }

                let bPosition = 0;
                for (
                  let i = 0;
                  i < disse.$store.state.stepFood.meals.categories.length;
                  i++
                ) {
                  if (
                    disse.$store.state.stepFood.meals.categories[i].term_id ==
                    btermID
                  ) {
                    bPosition = parseInt(
                      disse.$store.state.stepFood.meals.categories[i].position
                    );
                  }
                }
                return aPosition - bPosition;
              }
            );
          }
        });
      }
      this.$store.commit("ADD_FOODS", tmpReservations);
    },
  },
  mounted() {
    this.dates.forEach((date) => {
      this.meals.formulas.forEach((formula) => {
        this.quantity[
          this.getIndex(date, "formula", formula.ID)
        ] = this.$store.state.stepEvent.event_nb_people;
      });
      this.meals.carte.forEach((carte) => {
        this.quantity[
          this.getIndex(date, "carte", carte.ID)
        ] = this.$store.state.stepEvent.event_nb_people;
      });
      this.expandOpen[this.dates.indexOf(date)] = false;
    });
    this.expandOpen[0] = true;
  },
  updated() {
    this.buildReservations();
  },
  created() {
    this.$store.dispatch("getMeal");
  },
};
</script>