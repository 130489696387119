<template>
  <v-container>
    <div class="text-center" v-if="dialog">
      <!-- v-if="dialog" -->
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{ $store.state.text.success_message_title.value }}
            </v-card-title>
            <v-card-text style="padding-top: 20px">
              <span v-html="$store.state.text.success_message.value"></span>
            </v-card-text>
            <!-- <v-card-text style="padding-top: 20px">
            Votre devis personnalisé vient de vous être envoyé à l'adresse
            <strong>{{ this.$store.state.stepCoord.mail }}</strong
            >. Nous vous recontacterons dans les plus brefs délais pour
            organiser votre séminaire. À bientôt à La Villa K !
          </v-card-text> -->
            <v-card-actions class="justify-center">
              <v-btn class="white--text" color="#9BA88C" @click="reloadPage()">
                {{ $store.state.text.new_quote_btn_text.value }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-stepper v-model="current" color="green" v-else>
      <v-stepper-header id="custom-stepper-header">
        <v-stepper-step
          :editable="iseditable()"
          v-for="(step, n) in steps"
          :key="n"
          :complete="stepComplete(n + 1)"
          :step="n + 1"
          :rules="[(value) => !!step.valid]"
          :color="stepStatus(n + 1)"
          id="custom-stepper"
        >
          {{ step.name }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content v-for="(step, n) in steps" :step="n + 1" :key="n">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
                <component
                  :is="step.Component"
                  name="step.Component"
                ></component>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn v-if="n > 0" class="ma-0" text @click="clickBack(n)">{{
                $store.state.text.prev_btn_text.value
              }}</v-btn>
              <v-btn
                v-if="n + 1 < lastStep"
                class="white--text ma-0"
                color="#9BA88C"
                @click="validate(n)"
                :disabled="getDisabled(step, n)"
                >{{ $store.state.text.next_btn_text.value }}</v-btn
              >
              <v-btn
                v-else
                class="white--text"
                color="#E99688"
                @click="done()"
                >{{ $store.state.text.send_btn_text.value }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
    </v-stepper>
    <v-container fluid grid-list-md> </v-container>
    <div class="text-center">
      <v-dialog
        v-model="this.$store.state.APIConnexionFail"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{ $store.state.text.API_connection_error_dialog_title.value }}
          </v-card-title>
          <v-card-text>
            <span
              v-html="$store.state.text.API_connection_error_dialog.value"
            ></span>
            <v-btn class="white--text" color="#9BA88C" @click="reloadPage()">{{
              $store.state.text.refresh_btn_text.value
            }}</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-container fluid grid-list-md> </v-container>
    <div class="text-center">
      <v-dialog v-model="oneDayEventDialog" width="500" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{ $store.state.text.one_day_event_dialog_title.value }}
          </v-card-title>
          <v-card-text>
            <span v-html="$store.state.text.one_day_event_dialog.value"></span>
            <v-btn
              class="white--text"
              color="#9BA88C"
              @click="oneDayEventDialog = false"
              >{{ $store.state.text.ok_btn_text.value }}</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<style scoped>
@media screen and (max-width: 1240px) {
  #custom-stepper-header {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 800px) {
  #custom-stepper {
    margin: -20px;
  }
  #custom-stepper-header {
    padding-right: 20px;
    padding-left: 20px;
  }
}
</style>

<script>
import VueFormVee from "../components/VueFormVee";
import stepCoord from "../components/stepCoord";
import stepRooms from "../components/stepRooms";
import stepEvent from "../components/stepEvent";
import stepMeeting from "../components/stepMeeting";
import stepFood from "../components/stepFood";

export default {
  name: "VueForm",
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    },
  },
  components: {
    VueFormVee,
    stepRooms,
    stepCoord,
    stepEvent,
    stepMeeting,
    stepFood,
  },
  data: () => ({
    current: 1,
    lastStep: 6,
    steps: [
      {
        name: "Coordonnées",
        rules: [(v) => !!v || "Required."],
        valid: true,
        Component: "stepCoord",
      },
      {
        name: "Informations sur l'événement",
        rules: [(v) => !!v || "Required."],
        valid: true,
        Component: "stepEvent",
      },
      {
        name: "Salle de réunion",
        rules: [(v) => !!v || "Required."],
        valid: true,
        Component: "stepMeeting",
      },
      {
        name: "Chambres",
        rules: [(v) => !!v || "Required."],
        valid: true,
        Component: "stepRooms",
      },
      {
        name: "Restauration",
        rules: [(v) => !!v || "Required."],
        valid: true,
        Component: "stepFood",
      },
      {
        name: "Validation",
        rules: [(v) => !!v || "Required."],
        valid: true,
        Component: "VueFormVee",
      },
    ],
    valid: false,
    stepForm: [],
    otherParams: [],
    dialog: false,
    oneDayEventDialog: false,
  }),
  async created() {
    const languages = [
      {
        shortcode: "fr",
        plaintext: "",
        icon: "Flag_of_France.png",
      },
      {
        shortcode: "en",
        plaintext: "",
        icon: "Flag_of_United_Kingdom.png",
      },
      {
        shortcode: "de",
        plaintext: "",
        icon: "Flag_of_Germany.png",
      },
    ];

    this.$store.state.lang = navigator.language || navigator.userLanguage;
    if(this.$store.state.lang.includes("-"))
      this.$store.state.lang = this.$store.state.lang.substr(0, this.$store.state.lang.indexOf('-')) //removes sublanguage indicator, e.g. transform "en-GB" to "en"
      console.log("LANGAGE SET TO ",this.$store.state.lang);
    try { //if a language is passed as query variable then it is prior to the browser language
      let lang = this.getQueryVariable("lang");
      if (lang.length) this.$store.state.lang = lang;
    } catch (error) {
      console.error(error);
    }
    this.$store.dispatch("getOtherParams");
    await this.$store.dispatch("getEditableTexts");
    this.steps[0].name = this.$store.state.text.tab_step_1.value;
    this.steps[1].name = this.$store.state.text.tab_step_2.value;
    this.steps[2].name = this.$store.state.text.tab_step_3.value;
    this.steps[3].name = this.$store.state.text.tab_step_4.value;
    this.steps[4].name = this.$store.state.text.tab_step_5.value;
    this.steps[5].name = this.$store.state.text.tab_step_6.value;

    const iconsBaseURL =
      "https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/";

    const currentLanguage = languages.find(
      (lang) => lang.shortcode == this.$store.state.lang
    );

    const otherLanguages = languages.filter(
      (lang) => lang.shortcode != currentLanguage.shortcode
    );

    const currentImg = document
      .getElementById("current-lang")
      .getElementsByClassName("lang-flag")[0];
    currentImg.setAttribute("src", iconsBaseURL + currentLanguage.icon)

    const currentLink = document
      .getElementById("current-lang")
      .getElementsByClassName("lang-text")[0]
    currentLink.setAttribute("src", iconsBaseURL + currentLanguage.icon)
    currentLink.setAttribute('href', '?lang=' + currentLanguage.shortcode);
    currentLink.setAttribute("target", "_self");

    let otherLanguagesElement = document.getElementById("other-langs");
    otherLanguagesElement.innerHTML = "";

    otherLanguages.forEach((lang) => {
      <div class="selecting-lang">
        <img
          class="lang-flag"
          src="https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_Germany.png"
        />
        <a class="lang-text" href="?lang=de" target="_self">
          Deutsch
        </a>
      </div>;
      let newLanguageOption = document.createElement("div");
      newLanguageOption.setAttribute("class", "selecting-lang");

      const newA = document.createElement('a')
      newA.setAttribute('class', 'lang-text')
      newA.setAttribute('href', '?lang=' + lang.shortcode)
      newA.setAttribute("target", "_self")
      newLanguageOption.appendChild(newA)

      let newLanguageImg = document.createElement("img");
      newLanguageImg.setAttribute("class", "lang-flag");
      newLanguageImg.setAttribute("src", iconsBaseURL + lang.icon);
      newA.appendChild(newLanguageImg);

      let newLanguageText = document.createElement("a");
      newLanguageText.setAttribute("class", "lang-text");
      newLanguageText.setAttribute("href", "?lang=" + lang.shortcode);
      newLanguageText.setAttribute("target", "_self");
      newLanguageText.innerHTML = lang.plaintext;
      newLanguageOption.appendChild(newLanguageText);
      otherLanguagesElement.appendChild(newLanguageOption);
    });
  },
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
        }
      }
      console.log("Query variable %s not found", variable);
    },
    goToStep(targetStep) {
      this.current = targetStep;
    },
    getDisabled(step, n) {
      if(n==0 && !this.$store.state.reCaptchaOK)
        return true
      let res = !step.valid || (n == 1 && !this.$store.state.datesOK);
      return res;
    },
    clickBack(n) {
      if (
        this.current == 5 &&
        this.$store.state.stepEvent.date_start ==
          this.$store.state.stepEvent.date_end
      ) {
        this.current = n - 1;
      } else this.current = n;
      document.getElementById("app").scrollIntoView(); //reach top of the form
    },
    reloadPage() {
      window.location.reload();
    },
    stepComplete(step) {
      return this.current > step;
    },
    stepStatus(step) {
      return this.current > step ? "green" : "#E99688";
    },
    validate(n) {
      this.steps[n].valid = false;
      let v = this.$refs.stepForm[n].validate();
      if (v) {
        this.steps[n].valid = true;
        // continue to next
        if (
          this.current == 2 &&
          this.$store.state.stepEvent.date_start ==
            this.$store.state.stepEvent.date_end
        )
          this.oneDayEventDialog = true;
        if (
          this.current == 3 &&
          this.$store.state.stepEvent.date_start ==
            this.$store.state.stepEvent.date_end
        ) {
          this.current = n + 3;
        } else
        {
          if(this.current == 1)
          {
            this.$store.dispatch("registerDevis", {});
          }
          this.current = n + 2;
        }
        document.getElementById("app").scrollIntoView(); //reach top of the form
      }
    },
    done() {
      var tmpNeeds = [];
      this.$store.state.text.specific_needs.value.forEach((need, indexNeed) => {
        if (this.$store.state.stepEvent.specific_needs[indexNeed]) {
          tmpNeeds.push({
            need: need.short,
          });
        }
      });
      this.$store.state.stepEvent.specific_needs = tmpNeeds;
      this.otherParams = this.$store.state.otherParams;
      this.$store.dispatch("addDetails", {});
      this.dialog = true;
    },
    iseditable() {
      return process.env.NODE_ENV === "development" ? true : false;
    }
  },
};
</script>
