import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuetify from 'vuetify';

Vue.use(VueAxios, axios)
Vue.use(Vuetify);

import { ValidationProvider } from 'vee-validate';

// Register it globally
// main.js or any entry file.
Vue.component('ValidationProvider', ValidationProvider);

Vue.config.productionTip = false

Vue.filter('capitalize', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
    store,
    vuetify: new Vuetify({
        theme: {
            themes: {
                light: {
                    pink: '#E99688',
                    greenlight: '#9BA88C'
                }
            }
        }
    }),
    render: h => h(App)
}).$mount('#app')