var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.isError)?_c('div',[_vm._v(_vm._s(_vm.messages.error_api))]):(_vm.isLoading)?_c('div',[_c('vue-content-loading',{attrs:{"width":300,"height":100}},[_c('rect',{attrs:{"x":"18","y":"62","rx":"2","ry":"2","width":"73","height":"6"}}),_c('rect',{attrs:{"x":"19","y":"73","rx":"2","ry":"2","width":"73","height":"6"}}),_c('rect',{attrs:{"x":"13","y":"10","rx":"2","ry":"2","width":"83","height":"44"}}),_c('rect',{attrs:{"x":"58","y":"63","rx":"0","ry":"0","width":"1","height":"1"}}),_c('rect',{attrs:{"x":"108","y":"62","rx":"2","ry":"2","width":"73","height":"6"}}),_c('rect',{attrs:{"x":"108","y":"73","rx":"2","ry":"2","width":"73","height":"6"}}),_c('rect',{attrs:{"x":"103","y":"10","rx":"2","ry":"2","width":"83","height":"44"}}),_c('rect',{attrs:{"x":"148","y":"63","rx":"0","ry":"0","width":"1","height":"1"}})])],1):_vm._e(),_c('v-container',[_c('v-expansion-panels',{attrs:{"focusable":"","value":0}},_vm._l((_vm.dates),function(date,index){return _c('v-expansion-panel',{key:index,staticStyle:{"border":"solid #9ba88c 6px"}},[_c('v-expansion-panel-header',{staticStyle:{"font-size":"1.375rem"},attrs:{"expand-icon":_vm.getIcon(index),"color":"greenlight--text"},on:{"click":function($event){return _vm.expandToggled(index)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"pink"}},[_vm._v(" "+_vm._s(_vm.getIcon(index))+" ")])]},proxy:true}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.replaceVars(_vm.$store.state.text.day_tab_title.value, {
                dayIndex: _vm.dates.indexOf(date) + 1,
                day: new Date(date).toLocaleDateString(_vm.$store.state.lang),
              })
            )}})]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"justify-space-around",attrs:{"justify":"start","cols":"6"}},[(
                _vm.nbPeople >
                parseInt(_vm.$store.state.otherParams.nb_people_max_carte)
              )?_c('v-col',[_c('v-card',{staticClass:"my-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$store.state.text.meals_formula_title.value))]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$store.state.text.meals_formula_subtitle.value)+" ")]),_c('v-card-text',[_c('v-radio-group',{model:{value:(_vm.selected[_vm.getIndex(date, 'formula')]),callback:function ($$v) {_vm.$set(_vm.selected, _vm.getIndex(date, 'formula'), $$v)},expression:"selected[getIndex(date, 'formula')]"}},_vm._l((_vm.meals.formulas),function(formula,indexFormula){return _c('div',{key:indexFormula},[_c('v-radio',{attrs:{"label":formula.name,"value":1},on:{"change":function($event){_vm.prefill(_vm.getIndex(date, 'formula', formula.ID))}},model:{value:(formula.ID),callback:function ($$v) {_vm.$set(formula, "ID", $$v)},expression:"formula.ID"}}),(formula.desc)?_c('v-card-subtitle',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$store.state.text.benefits_desc_title.value)}}),_c('span',{domProps:{"innerHTML":_vm._s(formula.desc)}})]):_vm._e()],1)}),0)],1)],1)],1):_vm._e(),(
                _vm.nbPeople <=
                parseInt(_vm.$store.state.otherParams.nb_people_max_carte)
              )?_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',{staticClass:"my-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$store.state.text.meals_carte_title.value))]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$store.state.text.meals_carte_subtitle.value)+" ")]),_c('v-card-text',_vm._l((_vm.meals.categories),function(category,indexCategory){return _c('div',{key:indexCategory},[(_vm.getMealsByCategory(category.term_id).length)?_c('span',[_c('b',[_vm._v(_vm._s(category.name))])]):_vm._e(),_c('v-row',[_c('v-col',[(_vm.getMealsByCategory(category.term_id).length)?_c('v-select',{attrs:{"label":_vm.$store.state.text.default_meal_select_option.value,"items":_vm.getMealsByCategory(category.term_id),"item-text":"display_text","item-value":"ID","value":_vm.getSelectedCarte(date, category.term_id),"no-data-text":_vm.$store.state.text.empty_meal_category.value,"clearable":true},on:{"change":function($event){return _vm.setSelectedCarte($event, date, category.term_id)}}}):_vm._e(),(
                            _vm.getMealsByCategory(category.term_id).length &&
                            _vm.description[
                              _vm.getIndex(date, 'carte', category.term_id)
                            ]
                          )?_c('div',{staticClass:"mb-4"},[_c('i',[_c('span',{domProps:{"innerHTML":_vm._s(
                                _vm.description[
                                  _vm.getIndex(date, 'carte', category.term_id)
                                ]
                              )}})])]):_vm._e()],1),_c('v-col',[(_vm.displayMealQtyField(date, category.term_id))?_c('v-text-field',{attrs:{"name":"nb_single","label":"Nombre de personnes","outlined":"","type":"number","value":_vm.getQuantityCarte(date, category.term_id),"rules":_vm.carteNbPeopleRules},on:{"change":function($event){return _vm.setQuantityCarte($event, date, category.term_id)}}},[_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$store.state.text.label_event_nb_people.value)}})])],2):_vm._e()],1)],1),_c('v-row')],1)}),0)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","id":"custom-img"}},[(
                  _vm.nbPeople <=
                  parseInt(_vm.$store.state.otherParams.nb_people_max_carte) && _vm.meals.images_carte != null && _vm.meals.images_carte.length
                )?_c('v-card',{staticClass:"my-4"},[_c('v-carousel',_vm._l((_vm.meals.images_carte),function(item){return _c('v-carousel-item',{key:item,attrs:{"src":item}})}),1)],1):_vm._e(),(
                  _vm.nbPeople >
                  parseInt(_vm.$store.state.otherParams.nb_people_max_carte) && _vm.meals.images_formulas!= null && _vm.meals.images_formulas.length
                )?_c('v-card',{staticClass:"my-4"},[(_vm.meals.images_formulas.length)?_c('v-carousel',{attrs:{"id":"custom-img"}},_vm._l((_vm.meals.images_formulas),function(item){return _c('v-carousel-item',{key:item,attrs:{"src":item}})}),1):_vm._e()],1):_vm._e()],1)],1),_c('v-row',{staticClass:"justify-space-around",attrs:{"justify":"start","cols":"6"}},[(_vm.dates.indexOf(date) != _vm.dates.length - 1)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"#9BA88C"},on:{"click":function($event){return _vm.copyConfigToNextDays(date)}}},[_c('v-icon',[_vm._v("mdi-content-copy")]),_c('span',{attrs:{"id":"text-copy-next-day"}},[_vm._v(_vm._s(_vm.$store.state.text.copy_next_days_btn_text.value))])],1):_vm._e()],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }