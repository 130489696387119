<template>
  <v-container>
    <v-card class="mx-auto" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            {{ $store.state.text.validation_contact.value }}
          </div>
          <v-list-item-title class="headline mb-3">
            {{
              this.$store.state.stepCoord.gender == "homme"
                ? $store.state.text.male_gender.value
                : $store.state.text.male_gender.value
            }}
            {{ this.$store.state.stepCoord.firstname }}
            {{ this.$store.state.stepCoord.lastname }}
          </v-list-item-title>
          <v-list-item-subtitle
            ><span
              v-html="this.$store.state.text.label_validation_mail.value"
            ></span>
            {{ this.$store.state.stepCoord.mail }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="this.$store.state.stepCoord.phone"
            ><span
              v-html="this.$store.state.text.label_validation_phone.value"
            ></span>
            {{ this.$store.state.stepCoord.phone }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-card class="mx-auto" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            {{ $store.state.text.validation_event.value }}
          </div>
          <v-list-item-title class="headline mb-3">
            {{ this.$store.state.stepEvent.event_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span
              v-html="
                replaceVars($store.state.text.validation_dates.value, {
                  dateStart: new Date(
                    this.$store.state.stepEvent.date_start
                  ).toLocaleDateString($store.state.lang),
                  dateEnd: new Date(
                    this.$store.state.stepEvent.date_end
                  ).toLocaleDateString($store.state.lang),
                })
              "
            >
            </span>
            <!-- Dates : du
            {{
              new Date(
                this.$store.state.stepEvent.date_start
              ).toLocaleDateString()
            }}
            au
            {{
              new Date(
                this.$store.state.stepEvent.date_end
              ).toLocaleDateString()
            }} -->
          </v-list-item-subtitle>
          <v-list-item-subtitle
            ><span
              v-html="$store.state.text.label_validation_event_nb_people.value"
            ></span>
            {{ this.$store.state.stepEvent.event_nb_people }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span
              v-html="
                $store.state.text.label_validation_event_specific_needs.value
              "
            ></span>
            <v-chip-group style="margin-left: 10px">
              <div
                v-for="(need, indexNeed) in $store.state.text.specific_needs.value"
                :key="indexNeed">
                <v-chip v-if="$store.state.stepEvent.specific_needs[indexNeed]"> {{ need.short }} </v-chip>
              </div>
            </v-chip-group>
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="this.$store.state.stepEvent.customers_comment.length"
          >
            <span
              v-html="$store.state.text.label_validation_comment.value"
            ></span>
            {{ this.$store.state.stepEvent.customers_comment }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-card
      class="mx-auto"
      outlined
      v-for="(day, indexDay) in totalDays()"
      :key="indexDay"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4 text-center text-h6 greenlight--text">
            <span
              v-html="
                replaceVars($store.state.text.day_tab_title.value, {
                  dayIndex: indexDay + 1,
                  day: new Date(day).toLocaleDateString($store.state.lang),
                })
              "
            ></span>
          </div>
          <v-card class="mx-auto" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">
                  {{
                    $store.state.text.validation_meeting_rooms_subtitle.value
                  }}
                </div>
                <v-data-table
                  :headers="[
                    {
                      text:
                        $store.state.text.validation_table_header_meeting_room
                          .value,
                      value: 'name',
                    },
                    {
                      text:
                        $store.state.text
                          .validation_table_header_meeting_room_nb_people.value,
                      value: 'nb_people',
                    },
                    {
                      text:
                        $store.state.text
                          .validation_table_header_meeting_room_schedule.value,
                      value: 'display_schedule',
                    },
                    {
                      text:
                        $store.state.text
                          .validation_table_header_meeting_room_config.value,
                      value: 'selected_configuration',
                    },
                  ]"
                  :items="getReservationByDayAndType(day, 'meeting_rooms')"
                  :items-per-page="5"
                  no-data-text="Aucune salle de réunion sélectionnée pour cette journée"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template slot="no-data">
                    <span
                      v-html="
                        $store.state.text.validation_no_meeting_room.value
                      "
                    >
                    </span>
                  </template>
                  <template slot="item" slot-scope="data">
                    <tr>
                      <td>{{ data.item.name }}</td>
                      <td>{{ data.item.nb_people }}</td>
                      <td>{{ data.item.display_schedule }}</td>
                      <td>
                        <v-img
                          v-if="data.item.selected_configuration_icon"
                          max-height="50"
                          max-width="50"
                          dark
                          :src="data.item.selected_configuration_icon"
                          :style="data.item.disabled ? 'opacity: 0.3' : ''"
                          class="mr-10"
                          :alt="data.item.selected_configuration"
                        ></v-img>
                        <div v-else>
                          {{ data.item.selected_configuration }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <!-- <div
                  v-for="(reservation, indexRoom) in getReservationByDayAndType(
                    day,
                    'meeting_rooms'
                  )"
                  :key="indexRoom"
                >
                  <v-list>
                    <v-list-item>
                      Salle
                      <b style="padding-left: 5px; padding-right: 5px">{{
                        findMeetingRoom(reservation.m_room[0].ID).name
                      }}</b>
                      réservée pour
                      <b style="padding-left: 5px; padding-right: 5px"
                        >{{ reservation.nb_people }} personnes</b
                      >
                      en
                      <b style="padding-left: 5px; padding-right: 5px">{{
                        renderSchedule(reservation.schedule).toLowerCase()
                      }}</b>
                      en disposition
                      <b style="padding-left: 5px; padding-right: 5px">{{
                        reservation.selected_configuration
                      }}</b>
                    </v-list-item>
                  </v-list>
                </div> -->
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card class="mx-auto" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">
                  {{
                    $store.state.text.validation_sleeping_rooms_subtitle.value
                  }}
                </div>
                <v-data-table
                  :headers="[
                    {
                      text:
                        $store.state.text.validation_table_header_room.value,
                      value: 'name',
                    },
                    {
                      text:
                        $store.state.text
                          .validation_table_header_room_single_qty.value,
                      value: 'quantity_single',
                    },
                    {
                      text:
                        $store.state.text.validation_table_header_room_twin_qty
                          .value,
                      value: 'quantity_twin',
                    },
                  ]"
                  :items="getReservationByDayAndType(day, 'rooms')"
                  :items-per-page="5"
                  no-data-text="Aucune chambre sélectionnée pour cette journée"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template slot="no-data">
                    <span
                      v-html="
                        $store.state.text.validation_no_sleeping_room.value
                      "
                    >
                    </span>
                  </template>
                </v-data-table>
                <!-- <div
                  v-for="(reservation, indexRoom) in getReservationByDayAndType(
                    day,
                    'rooms'
                  )"
                  :key="indexRoom"
                >
                  <v-list>
                    <v-list-item>
                      Chambre
                      <b style="padding-left: 5px; padding-right: 5px">{{
                        findRoom(reservation.s_room[0].ID).name
                      }}</b>
                      réservée
                      <b style="padding-left: 5px; padding-right: 5px"
                        >en
                        {{
                          renderRoomReservation(
                            reservation.quantity_single,
                            reservation.quantity_twin
                          )
                        }}</b
                      >
                    </v-list-item>
                  </v-list>
                </div> -->
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card class="mx-auto" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">
                  {{ $store.state.text.validation_meals_subtitle.value }}
                  {{ renderMealsType() }}
                </div>
                <div
                  v-if="
                    renderMealsType() ==
                    $store.state.text.validation_meal_carte_suffix.value
                  "
                >
                  <v-data-table
                    :headers="[
                      {
                        text:
                          $store.state.text.validation_table_header_meal.value,
                        value: 'cat_name',
                      },
                      {
                        text:
                          $store.state.text
                            .validation_table_header_meal_carte_selected.value,
                        value: 'meal_name',
                      },
                      {
                        text:
                          $store.state.text
                            .validation_table_header_meal_carte_nb_people.value,
                        value: 'quantity',
                      },
                    ]"
                    :items="getReservationByDayAndType(day, 'meals_carte')"
                    :items-per-page="5"
                    no-data-text="Aucun repas sélectionné pour cette journée"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template slot="no-data">
                      <span
                        v-html="
                          $store.state.text.validation_no_meal_carte.value
                        "
                      >
                      </span>
                    </template>
                  </v-data-table>
                  <!-- <div
                    v-for="(
                      reservation, indexMeal
                    ) in getReservationByDayAndType(day, 'meals_carte')"
                    :key="indexMeal"
                  >
                    <v-list>
                      <v-list-item>
                        <b style="padding-left: 5px; padding-right: 5px">{{
                          findMealCategoryName(reservation.meal[0].ID)
                        }}</b>
                        :
                        <i style="padding-left: 5px; padding-right: 5px">{{
                          findMealCarte(reservation.meal[0].ID).name
                        }}</i>
                        pour
                        <b style="padding-left: 5px; padding-right: 5px"
                          >{{ reservation.quantity }} personnes</b
                        >
                      </v-list-item>
                    </v-list>
                  </div> -->
                </div>
                <div v-else>
                  <v-data-table
                    :headers="[
                      {
                        text:
                          $store.state.text.validation_table_header_meal_formula
                            .value,
                        value: 'name',
                      },
                      {
                        text:
                          $store.state.text
                            .validation_table_header_meal_formula_nb_people
                            .value,
                        value: 'quantity',
                      },
                    ]"
                    :items="getReservationByDayAndType(day, 'meals_formules')"
                    :items-per-page="5"
                    no-data-text="Aucune formule sélectionnée pour cette journée"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template slot="no-data">
                      <span
                        v-html="
                          $store.state.text.validation_no_meal_formula.value
                        "
                      ></span>
                    </template>
                  </v-data-table>
                  <!-- <div
                    v-for="(
                      reservation, indexMeal
                    ) in getReservationByDayAndType(day, 'meals_formules')"
                    :key="indexMeal"
                  >
                    <v-list>
                      <v-list-item>
                        Formule
                        <b style="padding-left: 5px; padding-right: 5px">{{
                          findMealFormula(reservation.formula[0].ID).name
                        }}</b>
                        pour
                        <b style="padding-left: 5px; padding-right: 5px"
                          >{{ reservation.quantity }} personnes</b
                        >
                      </v-list-item>
                    </v-list>
                  </div> -->
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-card class="mx-auto" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            {{ $store.state.text.validation_newsletter_subtitle.value }}
          </div>
          <v-checkbox
            v-model="$store.state.newsletterConsent"
            :label="$store.state.text.newsletter_consent_txt.value"
            class="my-n4"
            color="#E99688"
          ></v-checkbox>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-container>
</template> 
<script>
export default {
  name: "VueFormVee",
  data() {
    return {
      email: "",
      recap: "",
    };
  },
  methods: {
    dateToLocale(dateString) {
      var dateObject = new Date(dateString);
      return dateObject.toLocaleDateString(this.$store.state.lang);
    },
    replaceVars(
      source,
      replacements,
      openingDelimiter = "[",
      closingDelimiter = "]"
    ) {
      var res = source;
      for (const Old in replacements) {
        var New = replacements[Old];
        var regex = openingDelimiter + Old + closingDelimiter;
        res = res.replace(regex, New.toString());
      }
      return res;
    },
    renderMealsType() {
      return parseInt(this.$store.state.stepEvent.event_nb_people) >
        parseInt(this.$store.state.otherParams.nb_people_max_carte)
        ? this.$store.state.text.validation_meal_formula_suffix.value
        : this.$store.state.text.validation_meal_carte_suffix.value;
    },
    renderSchedule(schedule_slug) {
      let schedulesArray = this.$store.state.stepMeeting.room_schedule;
      let foundSchedule = schedulesArray.find(function (schedule) {
        if (schedule.option_slug == schedule_slug) {
          return schedule;
        }
      });
      if (foundSchedule != undefined) return foundSchedule.title;
      return "Non défini";
    },
    sortByDay() {
      let days = [];
      for (
        let i = 0;
        i < this.$store.state.stepMeeting.meetingRoomsReservations.length;
        i++
      ) {
        days.push({
          journee: this.$store.state.stepMeeting.meetingRoomsReservations[i]
            .journee,
          meeting_rooms: this.$store.state.stepMeeting.meetingRoomsReservations[
            i
          ].meeting_rooms,
        });
      }
      for (
        let i = 0;
        i < this.$store.state.stepRooms.roomsReservations.length;
        i++
      ) {
        days.push({
          journee: this.$store.state.stepRooms.roomsReservations[i].journee,
          rooms: this.$store.state.stepRooms.roomsReservations[i].rooms,
        });
      }
      for (
        let i = 0;
        i < this.$store.state.stepFood.mealsReservations.length;
        i++
      ) {
        days.push({
          journee: this.$store.state.stepFood.mealsReservations[i].journee,
          meals:
            this.$store.state.stepEvent.event_nb_people >
            parseInt(this.$store.state.otherParams.nb_people_max_carte)
              ? this.$store.state.stepFood.mealsReservations[i].meals_formula
              : this.$store.state.stepFood.mealsReservations[i].meals_carte,
        });
      }
      return days;
    },
    findMealCategoryName(mealID) {
      let catID = 0;
      for (let i = 0; i < this.$store.state.stepFood.meals.carte.length; i++) {
        if (this.$store.state.stepFood.meals.carte[i].ID == mealID) {
          catID = this.$store.state.stepFood.meals.carte[i].type_de_repas[0]
            .term_id;
          break;
        }
      }
      let categoryName = "";
      for (
        let i = 0;
        i < this.$store.state.stepFood.meals.categories.length;
        i++
      ) {
        if (this.$store.state.stepFood.meals.categories[i].term_id == catID) {
          categoryName = this.$store.state.stepFood.meals.categories[i].name;
          break;
        }
      }
      return categoryName;
    },
    getReservationByDayAndType(day, type) {
      switch (type) {
        case "meeting_rooms": {
          let ret = [];
          for (
            let i = 0;
            i < this.$store.state.stepMeeting.meetingRoomsReservations.length;
            i++
          ) {
            if (
              this.$store.state.stepMeeting.meetingRoomsReservations[
                i
              ].journee.getTime() == day.getTime()
            ) {
              ret = this.$store.state.stepMeeting.meetingRoomsReservations[i]
                .meeting_rooms;
              ret.forEach((meetingRoom) => {
                let foundMeetingRoom = this.findMeetingRoom(
                  meetingRoom.m_room[0].ID
                );
                meetingRoom.name = foundMeetingRoom.name;
                meetingRoom.display_schedule = this.renderSchedule(
                  meetingRoom.schedule
                ).toLowerCase();
                meetingRoom.selected_configuration_icon = "";
                for(let i = 0; i<foundMeetingRoom.dispositions_possibles.length; i++)
                 {
                   if(foundMeetingRoom.dispositions_possibles[i].nom_disposition == meetingRoom.selected_configuration)
                    meetingRoom.selected_configuration_icon = foundMeetingRoom.dispositions_possibles[i].icon.url
                 }
              });
            }
          }
          return ret;
        }
        case "rooms": {
          let ret = [];
          for (
            let i = 0;
            i < this.$store.state.stepRooms.roomsReservations.length;
            i++
          ) {
            if (
              this.$store.state.stepRooms.roomsReservations[
                i
              ].journee.getTime() == day.getTime()
            ) {
              ret = this.$store.state.stepRooms.roomsReservations[i].rooms;
              ret.forEach((room) => {
                room.name = this.findRoom(room.s_room[0].ID).name;
              });
            }
          }
          return ret;
        }
        case "meals_carte": {
          let ret = [];
          for (
            let i = 0;
            i < this.$store.state.stepFood.mealsReservations.length;
            i++
          ) {
            if (
              this.$store.state.stepFood.mealsReservations[
                i
              ].journee.getTime() == day.getTime()
            ) {
              ret = this.$store.state.stepFood.mealsReservations[i].meals_carte;
              ret.forEach((meal_carte) => {
                meal_carte.cat_name = this.findMealCategoryName(
                  meal_carte.meal[0].ID
                );
                meal_carte.meal_name = this.findMealCarte(
                  meal_carte.meal[0].ID
                ).name;
              });
            }
          }
          return ret;
        }
        case "meals_formules": {
          let ret = [];
          for (
            let i = 0;
            i < this.$store.state.stepFood.mealsReservations.length;
            i++
          ) {
            if (
              this.$store.state.stepFood.mealsReservations[
                i
              ].journee.getTime() == day.getTime()
            )
              ret = this.$store.state.stepFood.mealsReservations[i]
                .meals_formula;
            ret.forEach((meal_formule) => {
              meal_formule.name = this.findMealFormula(
                meal_formule.formula[0].ID
              ).name;
            });
          }
          return ret;
        }
      }
    },
    findMeetingRoom(ID) {
      let mRoomsArray = this.$store.state.stepMeeting.meetingRooms;
      let foundRoom = mRoomsArray.find(function (mRoom) {
        if (mRoom.ID == ID) {
          return mRoom;
        }
      });
      return foundRoom;
    },
    findRoom(ID) {
      let roomsArray = this.$store.state.stepRooms.rooms;
      let foundRoom = roomsArray.find(function (room) {
        if (room.ID == ID) {
          return room;
        }
      });
      return foundRoom;
    },
    findMealCarte(ID) {
      let mealsArray = this.$store.state.stepFood.meals.carte;
      let foundMeal = mealsArray.find(function (meal) {
        if (meal.ID == ID) {
          return meal;
        }
      });
      return foundMeal;
    },
    findMealFormula(ID) {
      let formulasArray = this.$store.state.stepFood.meals.formulas;
      let foundFormula = formulasArray.find(function (formula) {
        if (formula.ID == ID) {
          return formula;
        }
      });
      return foundFormula;
    },
    totalDays() {
      let days = [];
      this.sortByDay().forEach((day) => {
        let dayAlreadyInArray = false;
        days.forEach((dayInArray) => {
          if (dayInArray.getTime() == day.journee.getTime())
            dayAlreadyInArray = true;
        });
        if (!dayAlreadyInArray) {
          days.push(day.journee);
        }
      });
      days.sort((a,b) => {return a.getTime() - b.getTime()});
      return days;
    },
  },
};
</script>
