<template>
  <v-container>
    <v-row no-gutters cols="12" id="custom-row">
      <v-col cols="12" sm="6" id="custom-col">
        <span v-html="$store.state.text.warning_header_step_1.value"></span>
        <span v-html="$store.state.text.mandatory_fields_warning.value"> </span>
        <v-radio-group v-model="stepCoord.gender" row>
          <v-radio
            :label="$store.state.text.male_gender.value"
            value="homme"
          ></v-radio>
          <v-radio
            :label="$store.state.text.female_gender.value"
            value="femme"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-model="stepCoord.firstname"
          label="Prénom *"
          outlined
          :rules="firstnameRules"
        >
          <template slot="label">
            <span v-html="$store.state.text.label_firstname.value"> </span>
          </template>
        </v-text-field>
        <v-text-field
          v-model="stepCoord.lastname"
          label="Nom *"
          outlined
          :rules="nameRules"
        >
          <template slot="label">
            <span v-html="$store.state.text.label_name.value"> </span>
          </template>
        </v-text-field>
        <span v-html="$store.state.text.corporate_warning.value"> </span>
        <v-text-field
          v-model="stepCoord.company_code"
          label="Code société"
          outlined
        >
          <template slot="label">
            <span v-html="$store.state.text.label_company_code.value"> </span>
          </template>
        </v-text-field>
        <v-text-field
          v-model="stepCoord.mail"
          label="Adresse e-mail *"
          outlined
          :rules="mailRules"
        >
          <template slot="label">
            <span v-html="$store.state.text.label_mail.value"> </span>
          </template>
        </v-text-field>
        <v-text-field
          v-model="stepCoord.phone"
          label="Téléphone *"
          outlined
          :rules="phoneRules"
        >
          <template slot="label">
            <span v-html="$store.state.text.label_phone.value"> </span>
          </template>
        </v-text-field>
        <v-checkbox
          :rules="RGPDRules"
          :label="$store.state.text.rgpd_consent.value"
        >
        </v-checkbox>
        <vue-recaptcha
          loadRecaptchaScript
          ref="recaptcha"
          type="checkbox"
          :sitekey="getRecapPublic()"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
        >
        </vue-recaptcha>
      </v-col>

      <v-col cols="12" sm="6" id="custom-img">
        <v-img
           id="custom-img"
          class="ma-10"
          :src="$store.state.otherParams.image_step_1_url"
          transition="scale-transition"
          aspect-ratio="1.0"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@media screen and (max-width: 1100px) {
  #custom-img {
    display: none;
  }
  #custom-row {
    display: flex;
    justify-content: center;
    align-content: stretch;
  }
  #custom-col {
    max-width: 100%;
  }
}
</style>

<script src="https://unpkg.com/vue@latest/dist/vue.js" defer></script>
    <script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer></script>
    <script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js"></script>
    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.18.0/axios.js" defer></script>
<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "stepCoord",
    components: {
    VueRecaptcha,
  },
  data() {
    return {
      RGPDRules: [(v) => !!v || this.$store.state.text.valid_rgpd.value],
      nameRules: [
        (v) => !!v || this.$store.state.text.valid_required_name.value,
      ],
      firstnameRules: [
        (v) => !!v || this.$store.state.text.valid_required_firstname.value,
      ],
      mailRules: [
        (v) => !!v || this.$store.state.text.valid_required_mail.value,
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$store.state.text.valid_regex_match_mail.value,
      ],
      phoneRules: [
        (v) => !!v || this.$store.state.text.valid_required_phone.value,
      ],
    };
  },
  computed: {
    stepCoord: {
      get() {
        return this.$store.state.stepCoord;
      },
    },
  },
  methods: {
    getRecapPublic() {
      return process.env.VUE_APP_RECAPTCHA_PUBLIC;
    },
    onCaptchaVerified(recaptchaToken) {
      this.$store.state.reCaptchaOK = true;
      this.$store.state.reCaptchaToken = recaptchaToken;
    },
    onCaptchaExpired() {
      this.$store.state.reCaptchaOK = false;
    },
  },
};
</script>
