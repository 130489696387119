<template>
  <v-container>
    <div v-if="isError">{{ messages.error_api }}</div>
    <div v-else-if="isLoading">
      <vue-content-loading :width="300" :height="100">
        <rect x="18" y="62" rx="2" ry="2" width="73" height="6" />
        <rect x="19" y="73" rx="2" ry="2" width="73" height="6" />
        <rect x="13" y="10" rx="2" ry="2" width="83" height="44" />
        <rect x="58" y="63" rx="0" ry="0" width="1" height="1" />
        <rect x="108" y="62" rx="2" ry="2" width="73" height="6" />
        <rect x="108" y="73" rx="2" ry="2" width="73" height="6" />
        <rect x="103" y="10" rx="2" ry="2" width="83" height="44" />
        <rect x="148" y="63" rx="0" ry="0" width="1" height="1" />
      </vue-content-loading>
    </div>
    <!-- <v-alert color="orange" text>{{ messages.nb_people_max }}</v-alert> -->
    <v-container>
      <v-expansion-panels focusable :value="0">
        <v-expansion-panel
          v-for="(date, index) in dates"
          :key="index"
          style="border: solid #9ba88c 6px"
        >
          <v-expansion-panel-header
            style="font-size: 1.375rem"
            :expand-icon="getIcon(index)"
            color="greenlight--text"
            @click="expandToggled(index)"
          >
            <span
              v-html="
                replaceVars($store.state.text.day_tab_title.value, {
                  dayIndex: dates.indexOf(date) + 1,
                  day: new Date(date).toLocaleDateString($store.state.lang),
                })
              "
            ></span>
            <template v-slot:actions>
              <v-icon color="pink">
                {{ getIcon(index) }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="start" class="justify-space-around" cols="12">
              <v-col
                v-for="(meetingRoom, indexRoom) in meetingRooms"
                :key="indexRoom"
                cols="12"
                md="5"
              >
                <v-card class="my-4">
                  <v-img
                    id="custom-img"
                    v-if="meetingRoom.image"
                    height="400"
                    :src="meetingRoom.image.url"
                  ></v-img>
                  <v-card-title>
                    {{ meetingRoom.name | capitalize }}
                  </v-card-title>
                  <v-card-subtitle v-if="meetingRoom.surface">
                    <span v-html="replaceVars($store.state.text.label_surface.value, {'surface' : meetingRoom.surface})"></span>
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    <v-checkbox
                      color="#E99688"
                      :label="$store.state.text.label_select_meeting_room.value"
                      v-model="selected[getIndex(date, meetingRoom)]"
                      @change="prefill(getIndex(date, meetingRoom));$forceUpdate();"
                    >
                    </v-checkbox>
                    <div v-if="selected[getIndex(date, meetingRoom)]">
                      <v-text-field
                        name="nb_people"
                        label="Nombre de participants"
                        v-model="nb_people[getIndex(date, meetingRoom)]"
                        outlined
                        :rules="numberRules"
                        @change="
                          buildReservations();
                          $forceUpdate();
                        "
                        type="number"
                      >
                        <template slot="label">
                          <span v-html="$store.state.text.label_event_nb_people.value"></span>
                        </template>
                      </v-text-field>
                      <v-select
                        v-model="schedules[getIndex(date, meetingRoom)]"
                        :items="$store.state.stepMeeting.room_schedule"
                        :item-text="'title'"
                        :item-value="'option_slug'"
                        :aria-required="true"
                        :rules="scheduleRule"
                        outlined
                      >
                      <template slot="label">
                          <span v-html="$store.state.text.label_meeting_room_schedule.value"></span>
                      </template>
                      </v-select>
                      <v-select
                        v-model="configurations[getIndex(date, meetingRoom)]"
                        :items="getPossibleConfigurations(date, meetingRoom)"
                        :label="messages.label_choice_config"
                        :item-text="'nom_disposition'"
                        :item-value="'valeur_disposition'"
                        outlined
                        @click="$forceUpdate()"
                        :rules="configRule"
                      >
                        <template slot="label">
                          <span v-html="$store.state.text.label_meeting_room_config.value"></span>
                      </template>
                        <template slot="item" slot-scope="data">
                          <v-img
                            id="custom-img"
                            v-if="data.item.icon_url"
                            max-height="50"
                            max-width="50"
                            dark
                            :src="data.item.icon_url"
                            :style="data.item.disabled ? 'opacity: 0.3' : ''"
                            class="mr-10"
                          ></v-img>
                           <span v-html="data.item.nom_disposition"></span>
                        </template>
                      </v-select>
                    </div>
                  </v-card-text>
                  <v-card-subtitle v-if="meetingRoom.equipment.length">
                    <span v-html="$store.state.text.label_equipments.value"></span>
                    {{ meetingRoom.equipment.join(", ") }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="start" class="justify-space-around" cols="12">
              <v-btn
                id="custom-btn"
                class="white--text"
                color="#9BA88C"
                @click="copyConfigToNextDays(date)"
                v-if="dates.indexOf(date) != dates.length - 1"
              >
                <v-icon>mdi-content-copy</v-icon>
                <span id="text-copy-next-day">{{$store.state.text.copy_next_days_btn_text.value.toLowerCase()}}</span>
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <!-- <v-container fluid grid-list-md> </v-container> -->
  </v-container>
</template>

<style>
@media screen and (max-width: 800px) {
  #custom-btn {
    font-size: 8px;
    max-width: 100%;
  }
  #text-copy-next-day {
    display: none;
  }
  .v-label {
    font-size: 12px;
  }
  .v-card__title {
    font-size: 18px;
  }
}
</style>

<script>
import VueContentLoading from 'vue-content-loading';

export default {
  name: "stepMeeting",
  components: {
    VueContentLoading,
  },
  data() {
    return {
      messages: {
        error_api: `Une erreur est survenue lors du chargement du formulaire de réservation des salles`,
        label_choice_hours: `Choississez votre horaire`,
        label_choice_config: `Choisissez votre configuration`,
        nb_people_max: `Maximum 103 participants en utilisant les 2 salles (hors distanciation Covid)`,
      },
      numberRules: [
        (v) => !!v || this.$store.state.text.valid_required_event_nb_people.value,
        (v) =>
          (v && v >= 1) ||
          this.$store.state.text.valid_greater_than_1_meeting_room_nb_people.value,
      ],
      scheduleRule: [(v) => !!v || this.$store.state.text.valid_required_meeting_room_schedule.value],
      configRule: [(v) => !!v || this.$store.state.text.valid_required_meeting_room_config.value],
      isError: false,
      isLoading: false,
      selected: [],
      schedules: [],
      nb_people: [],
      configurations: [],
      expandOpen: [],
    };
  },
  computed: {
    meetingRooms() {
      return this.$store.state.stepMeeting.meetingRooms;
    },
    dates() {
      var tmpdates = [];
      var startDateString = this.$store.state.stepEvent.date_start;
      var startDate = new Date(startDateString);
      var endDateString = this.$store.state.stepEvent.date_end;
      var endDate = new Date(endDateString);

      var datesBetween = this.allDatesBetween(startDate, endDate);
      datesBetween.forEach((date) => {
        tmpdates.push(date);
      });
      return tmpdates;
    },
    stepMeeting: {
      get() {
        return this.$store.state.stepMeeting;
      },
    },
  },
  methods: {
    replaceVars(
      source,
      replacements,
      openingDelimiter = "[",
      closingDelimiter = "]"
    ) {
      var res = source;
      for (const Old in replacements) {
        var New = replacements[Old];
        var regex = openingDelimiter + Old + closingDelimiter;
        res = res.replace(regex, New.toString());
      }
      return res;
    },
    getIcon(index) {
      return this.expandOpen[index] ? "$minus" : "$plus";
    },
    expandToggled(index) {
      let previousState = this.expandOpen[index];
      for (let i = 0; i < this.expandOpen.length; i++) {
        this.expandOpen[i] = false;
      }
      this.expandOpen[index] = !previousState;
      this.$forceUpdate();
    },
    prefill(roomDateIndex) {
      this.nb_people[
        roomDateIndex
      ] = this.$store.state.stepEvent.event_nb_people;
    },
    getPossibleConfigurations(date, meetingRoom) {
      let configs = [];
      meetingRoom.dispositions_possibles.forEach((conf) => {
        configs.push({
          valeur_disposition: conf.nom_disposition,
          nom_disposition:
          this.replaceVars(this.$store.state.text.meeting_room_config_option.value, {'configName' : conf.nom_disposition, 'configMaxPeople' : conf.max_nb_participants}),
          icon_url: conf.icon.url,
          disabled:
            this.nb_people[this.getIndex(date, meetingRoom)] >
            parseInt(conf.max_nb_participants),
        });
      });
      return configs;
    },
    getIndex(date, meetingRoom) {
      var index = date + "-" + meetingRoom.ID.toString();
      return index;
    },
    dateDiff(date2, date1) {
      var diff = {}; // Initialisation du retour
      var tmp = date2 - date1;

      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes

      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes

      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures

      tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
      diff.day = tmp;

      return diff;
    },
    allDatesBetween(startDate, endDate) {
      var res = [];
      for (var i = 0; i <= this.dateDiff(endDate, startDate).day; i++) {
        var newDate = new Date(startDate);
        newDate.setDate(startDate.getDate() + i);
        res.push(newDate);
      }
      return res;
    },
    copyConfigToNextDays(dateStartingPoint) {
      var datesToCopy = this.dates.slice(
        this.dates.indexOf(dateStartingPoint) + 1,
        this.dates.length
      );
      datesToCopy.forEach((dest) => {
        this.meetingRooms.forEach((meetingRoom) => {
          if (this.selected[this.getIndex(dateStartingPoint, meetingRoom)]) {
            this.selected[this.getIndex(dest, meetingRoom)] = this.selected[
              this.getIndex(dateStartingPoint, meetingRoom)
            ];
            this.schedules[this.getIndex(dest, meetingRoom)] = this.schedules[
              this.getIndex(dateStartingPoint, meetingRoom)
            ];
            this.nb_people[this.getIndex(dest, meetingRoom)] = this.nb_people[
              this.getIndex(dateStartingPoint, meetingRoom)
            ];
            this.configurations[
              this.getIndex(dest, meetingRoom)
            ] = this.configurations[
              this.getIndex(dateStartingPoint, meetingRoom)
            ];
          } else {
            this.selected[this.getIndex(dest, meetingRoom)] = false;
          }
        });
      });
      this.$forceUpdate();
      this.buildReservations();
    },
    buildReservations() {
      var tmpReservations = [];
      this.dates.forEach((date) => {
        var oneReservationAtLeast = false;
        this.meetingRooms.forEach((meetingRoom) => {
          if (this.selected[this.getIndex(date, meetingRoom)])
            oneReservationAtLeast = true;
        });
        if (oneReservationAtLeast) {
          tmpReservations.push({
            journee: date,
            meeting_rooms: [],
          });
          this.meetingRooms.forEach((meetingRoom) => {
            if (this.selected[this.getIndex(date, meetingRoom)])
              tmpReservations[tmpReservations.length - 1].meeting_rooms.push({
                m_room: [
                  {
                    ID: meetingRoom.ID,
                  },
                ],
                schedule: this.schedules[this.getIndex(date, meetingRoom)],
                nb_people: this.nb_people[this.getIndex(date, meetingRoom)],
                selected_configuration: this.configurations[
                  this.getIndex(date, meetingRoom)
                ],
              });
          });
        }
      });
      this.$store.commit("ADD_MEETING_ROOMS", tmpReservations);
    },
  },
  mounted() {
    //prefill nb_people with event_nb_people
    this.dates.forEach((date) => {
      this.expandOpen[this.dates.indexOf(date)] = false;
    });
    this.expandOpen[0] = true;
  },
  updated() {
    this.buildReservations();
  },
  created() {
    this.$store.dispatch("getMeetingRooms");
  },
};
</script>
