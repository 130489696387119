import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

//Axios.defaults.withCredentials = true;
Axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        lang: "fr",
        user: null,
        //accessToken: null,
        APIConnexionFail: false,
        reCaptchaOK: false,
        redirected: false,
        reCaptchaToken: "",
        datesOK: true,
        stepCoord: {
            gender: "",
            firstname: "",
            lastname: "",
            partner_company: false,
            company_code: "",
            partner_company_name: "",
            mail: "",
            phone: "",
        },
        stepEvent: {
            event_name: '',
            date_start: '',
            date_end: '',
            event_nb_people: '',
            specific_needs: [],
            customers_comment: '',
        },
        stepMeeting: {
            meetingRooms: [],
            meetingRoomsReservations: [],
            room_schedule: [{
                    option_slug: "all-day",
                    title: "Journée (de 8h00 à 18h00)",
                    duration: "all-day",
                },
                {
                    option_slug: "morning",
                    title: "Matinée (de 8h00 à 12h00)",
                    duration: "half-day",
                },
                {
                    option_slug: "afternoon",
                    title: "Après-midi (de 12h00 à 18h00)",
                    duration: "half-day",
                },
            ],
        },
        stepRooms: {
            rooms: [],
            roomsReservations: []
        },
        stepFood: {
            meals: [],
            mealsReservations: []
        },
        otherParams: {
            partners: [],
            discounts: [],
            tax: 0,
            twin_extra_charge: 0,
            both_meeting_rooms_prices: [],
            duration_limits: [],
            nb_people_max_carte: 0,
            newsletter_label: "",
            image_step_1_url: "",
            image_step_2_url: ""
        },
        newsletterConsent: false,
        text: []
    },
    mutations: {
        DELETE_MEALS: (state) => {
            state.stepFood.mealsReservations = [];
            while (state.stepFood.mealsReservations.length > 0) {
                state.stepFood.mealsReservations.pop();
            }
        },

        SET_COORD: (state, gender, firstname, lastname, company_code, mail, phone) => {
            state.stepCoord.push({
                gender: gender,
                firstname: firstname,
                lastname: lastname,
                company_code: company_code,
                mail: mail,
                phone: phone,
            })
        },

        SET_OTHER_PARAMS: (state, input) => {
            state.otherParams = input
        },

        SET_EVENT: (state, event_name, date_start, date_end, event_nb_people, need_spa, need_care, need_giftcard, need_coach, need_fun_animation, need_cultural_animation, need_bistronomic_animation, need_tablets, customers_comment) => {
            state.stepEvent.push({
                event_name: event_name,
                date_start: date_start,
                date_end: date_end,
                event_nb_people: event_nb_people,
                need_spa: need_spa,
                need_care: need_care,
                need_giftcard: need_giftcard,
                need_coach: need_coach,
                need_fun_animation: need_fun_animation,
                need_cultural_animation: need_cultural_animation,
                need_bistronomic_animation: need_bistronomic_animation,
                need_tablets: need_tablets,
                customers_comment: customers_comment,
            })
        },

        SET_ROOMS: (state, data) => {
            state.stepRooms.rooms = data
            state.stepRooms.rooms.sort(function(a, b) { return (parseInt(a.order) - parseInt(b.order)) });
        },

        ADD_ROOMS: (state, data) => {
            state.stepRooms.roomsReservations = data
        },

        SET_FOOD: (state, data) => {
            state.stepFood.meals = data
        },

        ADD_FOODS: (state, data) => {
            state.stepFood.mealsReservations = data
        },

        SET_MEETING_ROOMS: (state, data) => {
            state.stepMeeting.meetingRooms = data
        },

        ADD_MEETING_ROOMS: (state, data) => {
            state.stepMeeting.meetingRoomsReservations = data
        },

        SET_TEXT: (state, data) => {
            state.text = data
            state.stepMeeting.room_schedule = data.selectable_schedules.value
        }
    },
    getters: {
        isAuthenticated: state => !!state.user,
        StateUser: state => state.user
    },
    actions: {
        async getRooms(context) {
            try {
                let rooms = (await Axios.get('/vue-data/rooms?lang=' + this.state.lang)).data;
                context.commit("SET_ROOMS", rooms);
                rooms.forEach(room => {
                    room.selected = [];
                    room.quantity_single = [];
                    room.quantity_twin = [];
                });
            } catch (error) {
                console.log("API_CONNECT_ERROR");
                this.state.APIConnexionFail = true;
            }
        },

        async getMeal(context) {
            try {
                let meals = (await Axios.get('/vue-data/meals?lang=' + this.state.lang)).data;
                context.commit("SET_FOOD", meals);
            } catch (error) {
                console.log("API_CONNECT_ERROR");
                this.state.APIConnexionFail = true;
            }
        },

        async getMeetingRooms(context) {
            try {
                let meetingRooms = (await Axios.get('/vue-data/meeting_rooms?lang=' + this.state.lang)).data;
                context.commit("SET_MEETING_ROOMS", meetingRooms);

                meetingRooms.forEach(room => {
                    room.show = false;
                    room.selected = false;
                    room.nb_people = '';
                    room.duration = '';
                });
            } catch (error) {
                console.log("API_CONNECT_ERROR");
                this.state.APIConnexionFail = true;
            }
        },

        async getOtherParams(context) {
            try {
                let otherParams = (await Axios.get('/vue-data/other_params')).data;
                context.commit("SET_OTHER_PARAMS", otherParams);
            } catch (error) {
                console.log("API_CONNECT_ERROR_1");
                this.state.APIConnexionFail = true;
            }
        },

        async getEditableTexts(context) {
            try {
                let editableTexts = (await Axios.get('/vue-data/editable_texts?lang=' + this.state.lang)).data;
                context.commit("SET_TEXT", editableTexts);
                document.getElementsByTagName("title")[0].text = editableTexts.browser_tab_title.value;
                document.getElementById("title").innerHTML = editableTexts.page_title.value;
                document.getElementById("contact").innerHTML = editableTexts.contact_btn_text.value;
                document.getElementById("subtitle").innerHTML = editableTexts.page_subtitle.value;
                document.getElementsByTagName("footer")[0].innerHTML = editableTexts.footer.value;
            } catch (error) {
                console.log("ERR_API_CONNECT : ", error);
                this.state.APIConnexionFail = true;
            }
        },

        async registerDevis() {
            try {
                let devisData = {
                    "title": this.state.stepCoord.lastname.toUpperCase() + " " + this.state.stepCoord.firstname.toUpperCase(),
                    "status": "draft",
                    "fields": {
                        "coord": this.state.stepCoord,
                    },
                    "recaptchaToken": this.state.reCaptchaToken,
                    "lang": this.state.lang
                };
                await Axios.post('/vue-data/devis', devisData, {
                    "Access-Control-Allow-Origin": "*",
                });
            } catch (error) {
                console.log("API_CONNECT_ERROR");
                this.state.APIConnexionFail = true;
            }
        },

        async addDetails() {
            try {
                let devisData = {
                    "fields": {
                        "coord": this.state.stepCoord,
                        "event": this.state.stepEvent,
                        "meeting_rooms": this.state.stepMeeting.meetingRoomsReservations,
                        "chambres": this.state.stepRooms.roomsReservations,
                        "meals": this.state.stepFood.mealsReservations,
                        "newsletterConsent": this.state.newsletterConsent
                    },
                    "recaptchaToken": this.state.reCaptchaToken,
                }
                await Axios.post('/vue-data/devis-data', devisData, {
                    "Access-Control-Allow-Origin": "*",
                });
            } catch (error) {
                console.log("API_CONNECT_ERROR");
                this.state.APIConnexionFail = true;
            }
        }
    },
    modules: {}
})